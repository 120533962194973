var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-3 py-4"},[_c('h1',[_c('i',{staticClass:"fas fa-book mr-2 my-1"}),_vm._v(" "+_vm._s(_vm.$store.state.language === "en" ? "Rules And Regulations" : "စည်းကမ်းချက်များ")+" ")]),_c('ul',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_c('li',[_c('div',{staticClass:"d-flex justify-content-center my-4",staticStyle:{"position":"fixed","bottom":"0","left":"0","right":"0"}},[_c('button',{staticClass:"btn btn-danger",staticStyle:{"background":"black","color":"white !important"},on:{"click":_vm.logout}},[_vm._v("အတည်မပြုပါ")]),_c('button',{staticClass:"btn btn-primary customzie-color",on:{"click":_vm.toHome}},[_vm._v("အတည်ပြုပါသည်")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁) ")]),_vm._v(" ဘော်ဒီ ဂိုးပေါင်း စုံမ တို့ကို အနည်းဆုံး (1,000) ကျပ် မှအများဆုံး (500,000) ကျပ်အထိဆော့ကစားနိုင်ပါသည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၂) ")]),_vm._v("ပွဲစဉ်များကို ပွဲကြီး ပွဲသေး အရောင်နဲ့ ပိုင်းခြားပြပြီး အကောက်မတူပါ။ "),_c('br'),_c('b',{staticClass:"ml-4"},[_vm._v("ပွဲကြီး - 5%")]),_vm._v(" "),_c('br'),_c('b',{staticClass:"ml-4"},[_vm._v("ပွဲသေး - 8%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၃) ")]),_vm._v("မောင်းများကို အနည်းဆုံး (၂)ပွဲမှ (၁၁)ပွဲအထိ ကစားနိုင်ပြီး၊ရွေးချယ်ထားသောပွဲစဥ်များအတွင်းမှ ပွဲပျက် (မကန်ဖြစ်သောပွဲစဥ်များ၊ ရွေ့ဆိုင်းထားသောပွဲစဥ်များ)ဖြစ်ခဲ့ပါက ကျန်ပွဲစဥ်များအတိုင်းအပြောင်းအလဲမရှိပဲအလျော်အစား ဆက်လက်လုပ်ဆောင်ပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၄) ")]),_vm._v("၂ သင်းမောင်း အကောက် 15% နှင့် ၃ သင်းမောင်းမှ ၁၁ သင်းမောင်းအထိ အကောက် 20% ကောက်ခံပါသည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၅) ")]),_vm._v("မောင်းအကောက် % တွင်ပွဲပျက် အားထည့်တွက်ထားပါသည်။ ဥပမာ - ၂ သင်းမောင်းထိုးထားပြီးတစ်ပွဲပျက်ပါက ဘော်ဒီအကောက်ဖြင့် မကောက်ပဲ နှစ်သင်းမောင်းအဖြစ်သာကောက်ပါသည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၆) ")]),_vm._v(" ရွေးထားသောမောင်းများမှ ရွေးချယ်ထားသောအသင်းများ ပွဲစဉ်အားလုံး မကန်ဖြစ်မှသာ လောင်းကြေးငွေအား ပြန်လည်ရရှိမည် ဖြစ်ပါသည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၇) ")]),_vm._v("သတ်မှတ်ရက်အတွင်း နောက်တစ်နေ့ 10:30am အတွင်း result (ပွဲစဥ်မပြီးသော) မထွက်သေးသောပွဲစဥ်များအား အလျော်အစား ပြုလုပ်ပေးမည်မဟုတ်ပါ။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၈) ")]),_vm._v(" ဂိုးရလာဒ်များကို မြန်မာကြေးထွက်ရှိသော Betting company (ibet789,bet365,sportbook365) များမှထွက်ရှိသောရလာဒ်များ ဖြင့်သာ အလျော်အစားပြုလုပ်ပြီး အဆိုပါကုမ္မဏီများမှ 9:00am အထိ မထွက်ရှိပါက flashscore..com, livescore..com တို့အား ကြည့်ရှူပြီး မတွေ့ရှိပါက သက်ဆိုင်ရာအဖွဲ့ချုပ်၏ ရလာဒ်များကိုသာအတည်ပြုပါသည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၉) ")]),_vm._v("ဂိုးရလာဒ်များထည့်သွင်းရာတွင်အချို့ပွဲစဥ်များသည် ညပိုင်းတွင်ရလာဒ်တစ်မျိုးထွက်ပေါ်ပြီး မနက်ပိုင်းတွင်ရလာဒ်တစ်မျိုးဖြင့် ပြင်ဆင်ပြောင်းလဲထွက်ပေါ်လာပါက နေ့ခင်း ၁၂ နာရီအထိသာပေးပို့အကြောင်းကြားလာသည့်ဘောင်ချာများကိုသာပြင်ဆင်ပြောင်းလဲ ပေးပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁၀) ")]),_vm._v("အချိန်စောကန်သွားသော ပွဲစဥ်များရှိပါက အခြား Betting company များနည်းတူမဟုတ်ပဲ F Bet တွင်မူလအချိန်အတိုင်း ဖွင့်လှစ်ထားမိပါက အဆိုပါပွဲများအား Cancel match ပြုလုပ်ပါမည်။အချိန်ပြောင်းလဲထားပါက အလျော်အစားပြုလုပ်ပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁၁) ")]),_vm._v("ပုံမှန်မဟုတ်သော ပွဲစဥ်များ ပါရှိပါက ၄င်းပွဲစဥ်များအား အလျော်အစားမပြုလုပ်ပဲ Cancel Match ပြုလုပ်ပြီး လောင်းကြေးငွေ ပြန်အမ်း ပေးပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁၂) ")]),_vm._v("သာမန်ကစားခြင်းမဟုတ်ပဲ ကြေးအဟ သီးသန့်ကြားထိုး ကစားပါက အဆိုပါ User ၏ကြားထိုး ကစားကြောင်းအား reject ပြုလုပ်ပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁၃) ")]),_vm._v("နည်းပညာချွတ်ယွင်း၍ အခြားသော Betting Company များနှင့်စျေးနှုန်း ပေါက်ကြေး များ (လွန်ကဲစွာ) ကွဲလွဲဖွင့်လှစ်မိပါက ယင်းစျေးနှုန်း ဖြင့် ကစားထားသော မောင်းနှင့်ဘော်ဒီများကို Cancel ပြုလုပ်ပြီး အရင်းငွေများကိုပြန်ပေးပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁၄) ")]),_vm._v("အငြင်းပွားဖွယ်ရာ ကိစ္စရပ်များပေါ်ပေါက်လာပါက အများနှင့် နှိုင်းယှဥ်သုံးသပ်ရသည့်အတွက် F Bet ၏ ဆုံးဖြတ်ချက်သာအတည်ဖြစ်ပါသည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h5',{staticClass:"text-center text-danger mt-4 mb-5 pb-3"},[_vm._v(" စည်းကမ်းချက်များကို သေချာစွာဖတ်ရှုပြီး သဘောတူပါကြောင်း အတည်ပြုပေးပါ။ ")])])
}]

export { render, staticRenderFns }