<template>
    <div class="px-3 py-4">
        <h1>
            <i class="fas fa-book mr-2 my-1"></i>
                {{
                    $store.state.language === "en"
                        ? "Rules And Regulations"
                        : "စည်းကမ်းချက်များ"
                }}
            </h1>
        <ul>
            <li class="my-4">
                <span class="text-danger"> (၁) </span> ဘော်ဒီ ဂိုးပေါင်း စုံမ တို့ကို အနည်းဆုံး (1,000) ကျပ် မှအများဆုံး (500,000) ကျပ်အထိဆော့ကစားနိုင်ပါသည်။

            </li>
            <li class="my-4">
                <span class="text-danger"> (၂) </span>ပွဲစဉ်များကို  ပွဲကြီး ပွဲသေး အရောင်နဲ့ ပိုင်းခြားပြပြီး အကောက်မတူပါ။ <br>
                 <b class="ml-4">ပွဲကြီး - 5%</b> <br>
                 <b class="ml-4">ပွဲသေး - 8%</b>
            </li>
            <li class="my-4">
                <span class="text-danger"> (၃) </span>မောင်းများကို အနည်းဆုံး (၂)ပွဲမှ (၁၁)ပွဲအထိ ကစားနိုင်ပြီး၊ရွေးချယ်ထားသောပွဲစဥ်များအတွင်းမှ ပွဲပျက် (မကန်ဖြစ်သောပွဲစဥ်များ၊ ရွေ့ဆိုင်းထားသောပွဲစဥ်များ)ဖြစ်ခဲ့ပါက ကျန်ပွဲစဥ်များအတိုင်းအပြောင်းအလဲမရှိပဲအလျော်အစား ဆက်လက်လုပ်ဆောင်ပါမည်။
            </li>
            <li class="my-4">
                <span class="text-danger"> (၄) </span>၂ သင်းမောင်း အကောက် 15% နှင့် ၃ သင်းမောင်းမှ ၁၁ သင်းမောင်းအထိ အကောက် 20% ကောက်ခံပါသည်။
            </li>
            <li class="my-4">
                <span class="text-danger"> (၅) </span>မောင်းအကောက် % တွင်ပွဲပျက် အားထည့်တွက်ထားပါသည်။ ဥပမာ - ၂ သင်းမောင်းထိုးထားပြီးတစ်ပွဲပျက်ပါက ဘော်ဒီအကောက်ဖြင့် မကောက်ပဲ နှစ်သင်းမောင်းအဖြစ်သာကောက်ပါသည်။
            </li>
            <li class="my-4">
                <span class="text-danger"> (၆) </span> ရွေးထားသောမောင်းများမှ ရွေးချယ်ထားသောအသင်းများ ပွဲစဉ်အားလုံး မကန်ဖြစ်မှသာ လောင်းကြေးငွေအား ပြန်လည်ရရှိမည် ဖြစ်ပါသည်။
            </li>
            <li class="my-4">
                <span class="text-danger"> (၇) </span>သတ်မှတ်ရက်အတွင်း နောက်တစ်နေ့ 10:30am အတွင်း result (ပွဲစဥ်မပြီးသော) မထွက်သေးသောပွဲစဥ်များအား အလျော်အစား ပြုလုပ်ပေးမည်မဟုတ်ပါ။
            </li>
            <li class="my-4">
                <span class="text-danger"> (၈) </span> ဂိုးရလာဒ်များကို မြန်မာကြေးထွက်ရှိသော Betting company (ibet789,bet365,sportbook365) များမှထွက်ရှိသောရလာဒ်များ ဖြင့်သာ အလျော်အစားပြုလုပ်ပြီး အဆိုပါကုမ္မဏီများမှ 9:00am အထိ မထွက်ရှိပါက flashscore..com, livescore..com တို့အား ကြည့်ရှူပြီး မတွေ့ရှိပါက သက်ဆိုင်ရာအဖွဲ့ချုပ်၏ ရလာဒ်များကိုသာအတည်ပြုပါသည်။
            </li>
            <li class="my-4">
                <span class="text-danger"> (၉) </span>ဂိုးရလာဒ်များထည့်သွင်းရာတွင်အချို့ပွဲစဥ်များသည် ညပိုင်းတွင်ရလာဒ်တစ်မျိုးထွက်ပေါ်ပြီး မနက်ပိုင်းတွင်ရလာဒ်တစ်မျိုးဖြင့် ပြင်ဆင်ပြောင်းလဲထွက်ပေါ်လာပါက နေ့ခင်း ၁၂ နာရီအထိသာပေးပို့အကြောင်းကြားလာသည့်ဘောင်ချာများကိုသာပြင်ဆင်ပြောင်းလဲ ပေးပါမည်။
            </li>
            <li class="my-4">
                <span class="text-danger"> (၁၀) </span>အချိန်စောကန်သွားသော ပွဲစဥ်များရှိပါက အခြား Betting company များနည်းတူမဟုတ်ပဲ F Bet တွင်မူလအချိန်အတိုင်း ဖွင့်လှစ်ထားမိပါက အဆိုပါပွဲများအား Cancel match ပြုလုပ်ပါမည်။အချိန်ပြောင်းလဲထားပါက အလျော်အစားပြုလုပ်ပါမည်။
            </li>

            <li class="my-4">
                <span class="text-danger"> (၁၁) </span>ပုံမှန်မဟုတ်သော ပွဲစဥ်များ ပါရှိပါက ၄င်းပွဲစဥ်များအား အလျော်အစားမပြုလုပ်ပဲ Cancel Match ပြုလုပ်ပြီး လောင်းကြေးငွေ ပြန်အမ်း ပေးပါမည်။
            </li>
            <li class="my-4">
                <span class="text-danger"> (၁၂) </span>သာမန်ကစားခြင်းမဟုတ်ပဲ ကြေးအဟ သီးသန့်ကြားထိုး ကစားပါက အဆိုပါ User ၏ကြားထိုး ကစားကြောင်းအား reject ပြုလုပ်ပါမည်။
            </li>
            <li class="my-4">
                <span class="text-danger"> (၁၃) </span>နည်းပညာချွတ်ယွင်း၍ အခြားသော Betting Company များနှင့်စျေးနှုန်း ပေါက်ကြေး များ (လွန်ကဲစွာ) ကွဲလွဲဖွင့်လှစ်မိပါက ယင်းစျေးနှုန်း ဖြင့် ကစားထားသော မောင်းနှင့်ဘော်ဒီများကို Cancel ပြုလုပ်ပြီး အရင်းငွေများကိုပြန်ပေးပါမည်။
            </li>
            <li class="my-4">
                <span class="text-danger"> (၁၄) </span>အငြင်းပွားဖွယ်ရာ ကိစ္စရပ်များပေါ်ပေါက်လာပါက အများနှင့် နှိုင်းယှဥ်သုံးသပ်ရသည့်အတွက် F Bet ၏ ဆုံးဖြတ်ချက်သာအတည်ဖြစ်ပါသည်။
            </li>
            <li >
                <h5 class="text-center text-danger mt-4 mb-5 pb-3">
                    စည်းကမ်းချက်များကို သေချာစွာဖတ်ရှုပြီး သဘောတူပါကြောင်း အတည်ပြုပေးပါ။
                </h5>
            </li>
            <li>
                <div class="d-flex justify-content-center my-4" style="position: fixed; bottom: 0; left: 0; right: 0;">
                    <button class="btn btn-danger" style="background: black; color: white !important;" @click="logout">အတည်မပြုပါ</button>
                    <button class="btn btn-primary customzie-color" @click="toHome">အတည်ပြုပါသည်</button>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['logoutUser']),
    logout() {
      this.logoutUser();
    },
    toHome() {
      this.$router.push('/home');
    },
  },
};
</script>

<style scoped>
.customzie-color {
    background-color: #182a4d !important;
    border-color: #182a4d !important;
}
</style>
